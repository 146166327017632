<template>
  <div class="outer-wrapper bg-blue-5 px-4 md:px-10 xl:px-20 py-10 lg:py-16 flex flex-col items-center">
    <div 
      v-if="$slots['header'] || $slots['sub-header']"
      class="w-full max-w-wrapper-md mx-auto"
      :class="headerWrapperClass"
    >
      <component
        :is="headerTag"
        v-if="$slots['header']"
        class="text-2.5xl md:text-3.5xl md:text-center mb-4 md:mb-6 w-full"
        :class="titleClass"
      >
        <slot name="header"></slot>
      </component>
      
      <p
        v-if="$slots['sub-header']"
        class="md:text-lg mb-6 md:mb-8 px-0 w-full"
        :class="subTitleClass"
      >
        <slot name="sub-header"></slot>
      </p>
    </div>

    <div 
      class="item-container w-full max-w-wrapper-md mx-auto grid grid-cols-1 gap-4 lg:gap-8"
      :class="[
        contentWrapperClass,
        itemPerRow ? `md:grid-cols-${itemPerRow}` : 'md:grid-cols-3'
      ]"
    >
      <div 
        v-for="(item, index) in items" 
        :key="index" 
        class="icon-item flex gap-4"
        :class="[
          cardStyles,
          itemClass
        ]"
      >
        <div 
          v-if="item.icon"
          class="icon flex-shrink-0"
          :class="iconClass"
        >
          <NuxtImg
            :src="item.icon.url"
            :width="item.icon?.width || 32"
            :height="item.icon?.height || 32"
            :alt="item.icon?.alt || item?.title || ''"
            loading="lazy"
            class="self-center mt-0.5"
          />
        </div>

        <div 
          class="icon-content" 
          :class="itemContentClass"
        >
          <p 
            v-if="item.title"
            class="font-bold"
            :class="itemTitleClass"
          >
            {{ item.title }}
          </p>
            
          <!-- eslint-disable vue/no-v-html -->
          <p 
            v-if="item.description" 
            :class="itemDescriptionClass"
            v-html="item.description"
          >
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="$slots.footer"
      class="mt-6 md:mt-10"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesHomeIconContent'
})

const props = defineProps({
  contentCard: {
    type: String,
    default: 'default',
    validator: value => ['default', 'theme-1'].includes(value)
  },

  items: {
    type: Array,
    required: true
  },

  itemPerRow: {
    type: [Number, String],
    default: 3
  },

  headerTag: {
    type: String,
    default: 'p',
    validator: value => {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div'].includes(value)
    }
  },

  sectionClass: {
    type: String,
    default: ''
  },

  headerWrapperClass: {
    type: String,
    default: ''
  },

  titleClass: {
    type: String,
    default: ''
  },

  subTitleClass: {
    type: String,
    default: ''
  },

  contentWrapperClass: {
    type: String,
    default: ''
  },

  itemClass: {
    type: String,
    default: ''
  },

  itemTitleClass: {
    type: String,
    default: ''
  },

  itemDescriptionClass: {
    type: String,
    default: ''
  },

  itemContentClass: {
    type: String,
    default: ''
  },

  iconClass: {
    type: String,
    default: ''
  }
})

const cardStyles = computed(() => {
  return getKey(props.contentCard, {
    'theme-1': 'flex !gap-6 p-[0.625rem] pb-3 md:p-6 shadow-sm-4 bg-white rounded-2xl items-center md:items-start'
  })
})
</script>
